<template>
  <div>
    <div style="text-align: center">
      <h3>Tambah Kontak</h3>
    </div>
    <br />
    <form v-on:submit.prevent="addContact">
      <label>Nama*</label>
      <input type="text" class="form-control" v-model="form.name" autofocus required />
      <br />
      <label>Nomor Whatsapp*</label>
      <input type="text" class="form-control" placeholder="08xxxxxxxx" v-model="form.number" required />
      <br />
      <a v-on:click="addVariable" class="btn btn-success text-white" style="display: inline-flex; margin: 5px">
        Tambah Variabel
      </a>
      <a v-on:click="deleteVariable" class="btn btn-danger text-white" style="display: inline-flex; margin: 5px">
        Hapus Variabel
      </a>
      <div class="form-group mb-20">
        <br>
        <div id="var">
          <div v-for="vars in form.variable" :key="vars.id">
            <label for="">Variabel {{ vars.id }}</label>
            <input class="form-control" type="text" v-model="vars.value" required />
            <br />
          </div>
        </div>
      </div>
      <!-- <label>Masukkan Kontak Ke Group</label>
      <select name="group_contact" v-model="form.group" class="form-control">
        <option :value="null_value" selected disabled>== PILIH GROUP ==</option>
        <option v-for="option in groupdd" :key="option.id" :value="option.id">{{ option.name }} [ID: {{ option.id }}]
        </option>
      </select> -->
      <br />
      <div class="button-group d-flex pt-25">
        <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
          simpan
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    props: ['groupdd'],
    data() {
      return {
        form: {
          name: "",
          number: "",
          variable: [],
          group: null
        },
        null_value: null
      };
    },
    mounted() {

    },
    methods: {
      toggleModal(param) {
        this.$modal.show(param);
      },
      addVariable() {
        this.form.variable.push({
          id: this.form.variable.length + 1,
          value: ""
        });
      },
      deleteVariable() {
        this.form.variable.splice(-1)
      },
      async addContact() {
        try {
          let response = await this.$store.dispatch('contact_create', this.form)
          this.$toast.success(response.data.message);
          this.$root.$emit("toggle_modal", "new_contact")
          this.$root.$emit("refresh_table", true)
        } catch (err) {
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
</script>