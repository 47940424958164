<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Edit Kontak</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-6">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="updateContact">
                                <label> Nama </label>
                                <input type="text" class="form-control" v-model="form.name">
                                <br>
                                <label> Nomor Whatsapp </label>
                                <input type="text" class="form-control" placeholder="08xxxxxxxx" v-model="form.number">
                                <br>
                                <a v-on:click="addVariable" class="btn btn-success text-white"
                                    style="display: inline-flex; margin: 5px">
                                    Tambah Variabel
                                </a>
                                <a v-on:click="deleteVariable" class="btn btn-danger text-white"
                                    style="display: inline-flex; margin: 5px">
                                    Hapus Variabel
                                </a>
                                <div class="form-group mb-20">
                                    <br>
                                    <div id="var">
                                        <div v-for="(vars, index) in form.variable" v-bind:key="index">
                                            <label for="">Variabel {{ vars.id }}</label>
                                            <input class="form-control" type="text" v-model="vars.value" required />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div class="button-group d-flex pt-25">
                                    <button type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                group_id: '',
                form: {
                    id: "",
                    name: "",
                    number: "",
                    variable: [],
                    group: null,
                },
                joined_group: [],
                g: {
                    columns: [{
                        label: "Group",
                        field: "name",
                    }],
                    rows: []
                },
                group: [],
                isLoading: true,
            };
        },
        mounted() {
            this.loadData()
            // this.group_id = this.$route.query.id
        },
        methods: {
            async loadData() {
                try {
                    let id = this.$route.query.id
                    let detail = await this.$store.dispatch("contact_detail", id)

                    let contactDetail = detail.data.data.contact
                    this.form.id = contactDetail.id 
                    this.form.name = contactDetail.name
                    this.form.number = contactDetail.number 
                    this.form.variable = contactDetail.data ? JSON.parse(contactDetail.data) : [] 
                    //STRINGIFY JSON

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/contact")
                }
            },
            addVariable() {
                this.form.variable.push({
                    id: this.form.variable.length + 1,
                    value: ""
                })
            },
            deleteVariable() {
                this.form.variable.splice(-1)
            },
            preselectedGroup() {
                for (let i = 0; i < this.g.rows.length; i++) {
                    for (let j = 0; j < this.joined_group.length; j++) {
                        if (this.g.rows[i].id == this.joined_group[j].group_id) {
                            this.$set(this.g.rows[i], 'vgtSelected', true)
                            break
                        }
                    }
                }
            },
            setPayloadGroup() {
                let payload = {
                    contact_id: this.$route.query.id,
                    add: [],
                    remove: []
                }

                let selected = this.$refs['group-table'].selectedRows
                let old = this.joined_group

                //ADD
                for (let i = 0; i < selected.length; i++) {
                    let status = false

                    for (let j = 0; j < old.length; j++) {
                        if (old[j].group_id == selected[i].id && status == false) {
                            status = true
                        }
                    }

                    if (status == false) {
                        payload.add.push({
                            id: selected[i].id
                        })
                    }
                }

                //REMOVE
                for (let i = 0; i < old.length; i++) {
                    let status = true
                    for (let j = 0; j < selected.length; j++) {
                        if (selected[j].id == old[i].group_id && status == true) {
                            status = false
                        }
                    }

                    if (status) {
                        payload.remove.push({
                            id: old[i].group_id
                        })
                    }
                }

                return payload
            },
            async updateContact() {
                try {
                    let response = await this.$store.dispatch('contact_update', this.form)
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async saveGroup() {
                let payload = await this.setPayloadGroup()

                try {
                    let response = await this.$store.dispatch('group_update_by_contact', payload)
                    this.$toast.success(response.data.message);
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>