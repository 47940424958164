<template>
    <div>
      <vue-good-table
        :pagination-options="{
            enabled: true,
            perPage: 5,
            perPageDropdown: [5, 10],
        }"
        :columns="columns"
        :rows="rows"/>
    </div>
  </template>
  
<script>
export default {
    props: ['rows'],
    data(){
        return {
            columns: [
            {
                label: 'Nama',
                field: 'name',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'No Whatsapp',
                field: 'number',
                filterOptions: {
                    enabled: true,
                },
            },
            ],
        };
    },
  };
  </script>