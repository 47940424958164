<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Edit Group Kontak</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="updateGroup">
                                <label> ID Group </label>
                                <input type="text" class="form-control" v-model="form.id" disabled>
                                <br>
                                <label> Nama Group </label>
                                <input type="text" class="form-control" v-model="form.name" required>
                                <br>
                                <button class="btn btn-success btn-block" id="simpan_edit" type="submit"> Simpan </button>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <h3>Anggota Group</h3>
                            </center>
                            <br>
                            <vue-good-table
                                ref="group-table"
                                :columns="g.columns"
                                :rows="g.rows"
                                :select-options="{ enabled: false, disableSelectInfo: true }"
                                :search-options="{ enabled: true }"
                                :pagination-options="{
                                    enabled: true,
                                }"
                                @on-search="handleSearch"
                            >
                                <template slot="table-column" slot-scope="props">
                                    <span v-if="props.column.label =='Select'">
                                        <input type="checkbox" :selected="selectAll" v-model="selectAll" @click="handleSelectAll">
                                    </span>
                                    <span v-else>
                                        {{ props.column.label }}
                                    </span>
                                </template>
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.label == 'Select'">
                                        <input type="checkbox" :selected="props.row['select']" v-model="props.row['select']" @click="handleSelect(props.row.id)">
                                    </span>
                                    <span v-else>
                                        {{ props.row[props.column.field] }}
                                    </span>
                                    <!-- {{ props }} -->
                                </template>
                            </vue-good-table>
                            <br>
                            <button v-on:click="saveGroup" class="btn btn-primary text-white btn-block">Simpan</button>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="new_member" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddMember :gid="group_id"/>
                </div>
            </modal>
            <modal name="new_member_bulk" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddGroupTable :gid="group_id" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
      data() {
        return {
            exportJson: [],
            form: {
                id: "",
                name: "",
            },
            group_id: "",
            isLoading: true,
            g: {
                columns: [
                    {
                        label: "Select",
                        field: "id",
                        type: "checkbox",
                        sortable: false,
                        width: '50px',
                    },
                    {
                        label: "Kontak",
                        field: "name",
                    }
                ],
                rows: []
            },
            selectAll: false,
        };
      },
      mounted() {
        this.group_id = this.$route.query.id
        this.loadData()
        this.loadAllContact()
      },
      methods: {
        async loadData(){
            try{
                let detail = await this.$store.dispatch("group_detail", { id: this.group_id })
                this.form = detail.data.data.group
                this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                this.$router.push("/group")
            }
        },
        async loadAllContact(){
            try{
                let detail = await this.$store.dispatch("contact_list")
                this.g.rows = detail.data.data.contacts

                this.setSelected()
            }catch(err){
                this.$toast.error(err.response.data.message)
            }
        },
        async handleSearch(){
        },
        async handleSelect(id) {
            const findIndex = this.g.rows.findIndex((row) => row.id == id)
            if (findIndex !== -1) {
                this.$set(this.g.rows[findIndex], 'select', !this.g.rows[findIndex].select)
                this.$set(this.g.rows[findIndex], 'vgtSelected', !this.g.rows[findIndex].select)
            }
            // Set selectAll
            setTimeout(() => {

                let selected = this.g.rows.filter((row) => row.select == true)
                if (selected.length == this.g.rows.length) {
                    this.selectAll = true
                } else {
                    this.selectAll = false
                }
            }, 1000)
        },
        async handleSelectAll(){
            for (let i = 0; i < this.g.rows.length; i++) {
                this.$set(this.g.rows[i], 'select', !this.selectAll)
                this.$set(this.g.rows[i], 'vgtSelected', !this.selectAll)
            }

            // this.g.rows.map((row) => {
            //     row['vgtSelected'] = row.select
            // })
        },
        async setSelected(){
            for (let i = 0; i < this.g.rows.length; i++) {
                    for (let j = 0; j < this.form.anggota.length; j++) {
                        if (this.g.rows[i].name + "-" + this.g.rows[i].number == this.form.anggota[j]) {
                            this.$set(this.g.rows[i], 'select', true)
                            this.$set(this.g.rows[i], 'vgtSelected', true)
                            break
                        } else {
                            this.$set(this.g.rows[i], 'select', false)
                            this.$set(this.g.rows[i], 'vgtSelected', false)
                        }
                    }
                }
        },
        async updateGroup(){
            try{
                let response = await this.$store.dispatch('group_update', this.form)
                this.$toast.success(response.data.message);
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
        },
        async exportContact(){
            try{
                this.$toast.info("Mohon Tunggu...");

                await this.$store.dispatch('group_member_export', { group_id: this.group_id })
                .then((response) => {
                    let rawData = response.data.data.contacts
                    let final = []
                    for(let i = 0;i < rawData.length;i++){
                        if(rawData[i].number.startsWith('0')){
                            rawData[i].number = '62' + rawData[i].number.substr(1)
                        }

                        final.push({
                            "Nama": rawData[i].name,
                            "Whatsapp": rawData[i].number,
                        })

                        let vars

                        if(rawData[i].data){
                            vars = JSON.parse(rawData[i].data)
                        }else{
                            vars = []
                        }
                        for(let j = 0;j < 5;j++){
                            final[i]['VARIABEL '+(j+1)] = vars[j]?.value ?? ""
                        }
                    }

                    this.exportJson = final
                })
                .then(() => {
                    this.$refs.exportNow.$el.click()
                })
            }catch(err){
                this.$toast.error(err);
            }
        },
        async repairGroup(){
            try{
                await this.$store.dispatch('group_repair', { group_id: this.group_id })
                .then((response) => {
                    this.$root.$emit("refresh_table", true)
                    this.$toast.success(response.data.message);
                })
            }catch(err){
                this.$toast.error(err);
            }
        },
        async saveGroup(){
            // let selected = this.$refs['group-table'].selectedRows
            let selected = this.g.rows.filter((row) => row.select == true)

            let finalDummy = []
            for (let i = 0; i < selected.length; i++) {
                finalDummy.push(selected[i].name + "-" + selected[i].number)
            }

            this.form.anggota = finalDummy
            this.updateGroup()
        },
        toggleModal(param) {      
            this.$modal.toggle(param);
        },
      },
    };
</script>    