<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Kontak
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn" style="float:inline-end !important">
                            <a v-on:click="emptyContact" class="btn px-15 btn-danger text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-trash fs-16"></i>
                                Kosongkan Kontak
                            </a>
                            <a v-on:click="toggleModal('import_contact')" class="btn px-15 btn-success text-white"
                                data-toggle="modal" data-target="#import" style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Kontak
                            </a>
                            <a v-on:click="exportContact" class="btn px-15 btn-secondary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-download fs-16"></i>
                                Export Kontak
                            </a>
                            <download-csv ref="exportNow" :data="exportJson" name="whacenter_kontak.csv"
                                style="display: none"></download-csv>
                            <!-- <a v-on:click="repairContact()" class="btn px-15 btn-info text-white"
                                style="display: inline-flex; margin: 5px">
                                Repair Kontak (Dari Migrasi V2)
                            </a> -->
                            <a v-on:click="toggleModal('new_contact')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Kontak
                            </a>
                            <!-- Modal -->
                            <div class="modal fade new-member" id="import" role="dialog"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content radius-xl">
                                        <div class="modal-header">
                                            <h6 class="modal-title fw-500" id="staticBackdropLabel">
                                                Import Kontak
                                            </h6>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span data-feather="x"></span>
                                            </button>
                                        </div>
                                        <div class="modal-body">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <ContactTable />
                    </div>
                </div>
            </div>
            <modal name="new_contact" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddContact :groupdd='group_dropdown' />
                </div>
            </modal>
            <modal name="import_contact" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ImportContact :groupdd='group_dropdown' />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import ContactTable from "@/components/tables/Contact.vue";
    import AddContact from "@/components/forms/contact/Add.vue";
    import ImportContact from "@/components/forms/contact/Import.vue";

    export default {
        components: {
            ContactTable,
            AddContact,
            ImportContact
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: []
            };
        },
        beforeDestroy() {
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            async loadGroups() {
                try {
                    let response = await this.$store.dispatch('group_list_dropdown')
                    this.group_dropdown = response.data.data.groups
                } catch (err) {
                    this.$toast.error("Server error")
                }
            },
            async emptyContact() {
                if (confirm("Semua data kontak akan terhapus, Yakin ?")) {
                    try {
                        let response = await this.$store.dispatch('contact_empty')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async repairContact() {
                if (confirm("Data kontak akan diperbaiki, mohon backup data sebelum melakukan repair kontak. Konfirmasi ?")) {
                    try {                        
                        this.$toast.success("Repair kontak sedang diproses");
                        let response = await this.$store.dispatch('contact_repair')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async exportContact() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('contact_export')
                        .then((response) => {
                            let rawData = response.data.data.contacts
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                if (rawData[i].number.startsWith('0')) {
                                    rawData[i].number = '62' + rawData[i].number.substr(1)
                                }

                                final.push({
                                    "Nama": rawData[i].name,
                                    "Whatsapp": rawData[i].number,
                                })

                                let vars

                                if (rawData[i].data) {
                                    vars = JSON.parse(rawData[i].data)
                                } else {
                                    vars = []
                                }
                                for (let j = 0; j < 5; j++) {
                                    final[i]['VARIABEL ' + (j + 1)] = vars[j]?.value?? ""
                                }
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportNow.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            fillVar(val) {
                return val
            },
            toggleModal(param) {
                // if (this.group_dropdown.length == 0) this.loadGroups()

                this.$modal.toggle(param);
            },
        },
    };
</script>