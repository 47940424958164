<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Group
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('new_group')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Group
                            </a>
                            <!-- Modal -->
                            <div class="modal fade new-member" id="import" role="dialog"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content radius-xl">
                                        <div class="modal-header">
                                            <h6 class="modal-title fw-500" id="staticBackdropLabel">
                                                Import Kontak
                                            </h6>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span data-feather="x"></span>
                                            </button>
                                        </div>
                                        <div class="modal-body">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <GroupTable />
                    </div>
                </div>
            </div>
            <modal name="new_group" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddGroup />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import GroupTable from "@/components/tables/Group.vue";
    import AddGroup from "@/components/forms/group/Add.vue";

    export default {
        components: {
            GroupTable,
            AddGroup,
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: []
            };
        },
        beforeDestroy() {
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>